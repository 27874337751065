import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, Type } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';
import { Subject, concatMap, distinctUntilChanged, filter, map, merge, of, takeUntil, tap } from 'rxjs';
import { WMLConstructorDecorator, WMLCustomComponent, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { CardOneProps } from '../card-one/card-one.component';
import { WMLButtonPropsTypeEnum, WMLButtonOneProps } from '@windmillcode/angular-wml-button';
import { JobsService } from '@shared/services/jobs/jobs.service';
import { ScrollPageProps } from '@core/utility/scroll-utils';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';
import { WMLDataSourceUpdateSourcesObj } from '@core/utility/data-source-utils';
import { ListJobsEntity, ListJobsUIRequestBody, ListJobsEntity as RunJobsEntity } from '@shared/services/jobs/listJobs';
import { EventSubjBodyZero, documentQuerySelector, transformObjectKeys, triggerEvent } from '@core/utility/common-utils';
import { transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from '@core/utility/string-utils';
import { WMLAccordionZeroItemProps, WMLAccordionZeroProps } from '@windmillcode/angular-wml-accordion';
import { JobTitleZeroComponent, JobTitleZeroProps } from '../job-title-zero/job-title-zero.component';
import { DownloadJobZeroComponent, DownloadJobZeroItemProps, DownloadJobZeroProps } from '../download-job-zero/download-job-zero.component';
import { AiShortsJobZeroComponent, AiShortsJobZeroProps } from '../ai-shorts-job-zero/ai-shorts-job-zero.component';
import { RunJobsUIRequestBody } from '@shared/services/jobs/runJobs';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import { UpdateJobsUIRequestBody } from '@shared/services/jobs/updateJobs';
import { SocketioService } from '@shared/services/socketio/socketio.service';
import { cantModifyWhenJobStatusAreTheseTypes } from '@core/utility/specific-utils/specific-utils';
import { JobsZeroCommonProps } from './jobs-zero';
import { SpecificService } from '@core/specific/specific.service';
import { BackupPreviousStateJobZeroComponent, BackupPreviousStateJobZeroProps } from '../backup-previous-state-job-zero/backup-previous-state-job-zero.component';
import { UpdateVideosJobZeroComponent, UpdateVideosJobZeroProps } from '../update-videos-job-zero/update-videos-job-zero.component';
import { JobStatusZeroProps } from '../job-status-zero/job-status-zero.component';
import { deepCopyInclude } from '@core/utility/object-utils';
import { PlatformsService } from '@shared/services/platforms/platforms.service';
import { TransferLocalFilesJobZeroComponent, TransferLocalFilesJobZeroProps } from '../transfer-local-files-job-zero/transfer-local-files-job-zero.component';

@Component({
    selector: 'jobs-zero',
    templateUrl: './jobs-zero.component.html',
    styleUrls: ['./jobs-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class JobsZeroComponent  {
  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService,
    public jobsService:JobsService,
    public accountsService:AccountsService,
    public socketioService:SocketioService,
    public specificService:SpecificService,
    public platformsService:PlatformsService
  ) { }

  classPrefix = generateClassPrefix('JobsZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.jobsZero)
  @Input('props') props: JobsZeroProps = new JobsZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  ENV = ENV
  JSON = JSON


  addJob= (props = new RunJobsEntity({
    jobType:"BULK_DOWNLOAD",
  }))=>{
    let job = new RunJobsEntity({
      ...props,
      status: props.status ??"NOT_STARTED",
      priority:"MEDIUM",
      id:props.id ??this.jobsService.getIdForNewItemInListJobsDataSource()
    })
    return this.jobsService.listJobsDataSource.updateSources(
      [new WMLDataSourceUpdateSourcesObj({
        row:transformObjectKeys( job,transformFromCamelCaseToSnakeCase),
        configurations:[
          {
            key:"[[],[]]",
            action:"insert"
          }
        ]
      })]
    )
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        triggerEvent(documentQuerySelector("."+this.classPrefix("Pod1")),"scroll")
        this.cdref.detectChanges()
      })
    )

  }

  deleteJob = (id,card:JobsZeroPropsCard)=>{
    card.ngUnsub.next()
    // console.log(this.jobsService.listJobsDataSource.currentSource.data)
    // console.log(id)
    return this.jobsService.listJobsDataSource.updateSources(
      [new WMLDataSourceUpdateSourcesObj({
        row:{
          is_deleted:true
        },
        id,
        configurations:[
          {
            action:"update"
          }
        ]
      })]
    )
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        this.cdref.detectChanges()
      })
    )

  }

  listenForFinishedJobs = ()=>{
    return merge(
      this.socketioService.bulkVideoDownloadJobEvent,
      this.socketioService.backupPreviousStateEvent,
      this.socketioService.updateVideosEvent,
      this.socketioService.fileTransferEvent
    )
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((res)=>{

        return this.jobsService.updateJobIds(res.data.result.updated_job_ids ??[])
        .pipe(
          takeUntil(this.ngUnsub),
          map(()=>{
            return res
          })
        )

      }),
      concatMap((res)=>{
        let sourcesToUpdate = res.data.result.after_job_info.map((job)=>{

          let targetJob = this.listJobs.find((job1)=>{
            return job1.id === job["job_id"]
          })
          if(targetJob?.jobType ==="TRANSFER_LOCAL_FILES" && job.details?.transfer_local_files_details){
            targetJob.transferLocalFilesJobZeroProps.setState(
              transformObjectKeys(job.details.transfer_local_files_details,transformFromSnakeCaseToCamelCase))
          }

          return new WMLDataSourceUpdateSourcesObj({
            row:deepCopyInclude(
              [["SUCCESS"].includes(job.status) || job.force_datasource_update ===true ? "details":null,"result_urls","status"],job
            ),
            id:job["job_id"],
            configurations:[{
              action:"update"
            }]
          })
        })

        return this.jobsService.listJobsDataSource.updateSources(
          sourcesToUpdate
        )
        .pipe(
          map(()=>{
            let needNewToken = res.data.result.after_job_info.some((job)=>{
              return ["ISSUES","FAILED"].includes(job.status)
            })
            if(needNewToken){
              this.specificService.reconnectToAPlatform({navigate:false})
            }
            return res
          })
        )
      }),
      concatMap((res)=>{
        if(res.data.result.prevent_scroll_reset === true ){
          return of(null)
        }
        else{
          return this.jobsScrollObj.populateItemsAtStart()


        }

      })
    )

  }

  listenForJobUpdate = (card:JobsZeroPropsCard)=>{
    return merge(
      card.selectJobTypeEvent,
      card.addVideoEvent,
      card.donwloadJobZeroProps.deleteVideoEvent,
      card.getJobTitleProps().jobTypeChangeEvent
    )
    .pipe(
      takeUntil(card.ngUnsub),
      takeUntil(this.ngUnsub),
      tap((res:any)=>{
        this.updateTitleBtn("global.saveBtnNotSaved")
      // must be like this for some reason items do not sync`
        if(res?.type === "deleteVideo"){
          card.donwloadJobZeroProps.items = res.val
        }
      }),
      concatMap(()=>{
        let newObj = new RunJobsEntity({
          jobType:card.getJobTitleProps().jobType,
          details:{}
        })
        if(card.getJobTitleProps().jobType ==="BULK_DOWNLOAD"){
          newObj.details.bulkDownloadDetails = {
            videos:card.donwloadJobZeroProps.items.map((item)=>{
              return {
                ...item,
                includeAudio:true,
                includeThumbnail:true,
                includeVideo:true,
                includeDescription:true,
                includeTags:true
              }
            })
          }
        }
        let {jobInfo} = card.donwloadJobZeroProps
        if( ["SUCCESS","FAILED"].includes(jobInfo.status)){
          newObj.status = "NOT_STARTED"
          jobInfo.setState({status:"NOT_STARTED"})
        }
        return this.jobsService.listJobsDataSource.updateSources([
          new WMLDataSourceUpdateSourcesObj({
            row:transformObjectKeys(newObj,transformFromCamelCaseToSnakeCase),
            id:card.id,
            configurations:[{
              action:"update"
            }]
          })
        ])
      }),

    )

  }

  listenForSelectJob = (target:WMLAccordionZeroItemProps)=>{
    return target.accordionTitle.props.jobChosenEvent
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        this.listJobs.map((job)=>{
          let item = job.card.items[0][0]
          if(item !== target){
            item.accordionTitle.props.jobChosenSubj.next(new EventSubjBodyZero({
              value:false,
              emit:false
            }))
          }
        })
      })
    )
  }

  listenForJobTypeChange = (target:JobsZeroPropsCard)=>{
    return target.getJobTitleProps().jobTypeChangeEvent
    .pipe(
      takeUntil(this.ngUnsub),
      distinctUntilChanged(),
      tap((res)=>{
        target.jobType = res
        target.startJobBtn.isPresent = !(["UPDATE_VIDEOS","BACKUP_PREVIOUS_STATE"].includes(res))
        let newBody = new WMLCustomComponent(target.props)
        target.getAccordionCpnt().accordionBody = newBody
        target.getAccordionCpnt().updateAccordionBodySubj.next(newBody)
        target.selectJobTypeEvent.next()
      })
    )

  }

  listenForAddVideo = ()=>{
    return this.props.addVideosSubj
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((res)=>{
        let chosenBulkDownload = this.listJobs.find((job)=>{
          let props = job.getJobTitleProps()
          return props.jobIsChosen && props.jobType === "BULK_DOWNLOAD"
        })
        if(!chosenBulkDownload){
          return this.addJob(res)

        }
        // @ts-ignore
        else if (cantModifyWhenJobStatusAreTheseTypes(chosenBulkDownload.props.props.jobInfo.status)){
          this.baseService.createWMLNote("JobsZero.WMLNotifyOne.jobInProgressCantAddVideo")
          return of(null)
        }
        else {
          let props = chosenBulkDownload.donwloadJobZeroProps

          let newVideos = res.details.bulkDownloadDetails?.videos
          .filter((video)=>{
            return !props.items.find((item)=>item.downloadUrl === video.downloadUrl)
          })
          .map((video)=>new DownloadJobZeroItemProps(video))
          props.items.push(...(newVideos??[]))
          props.setState()
          this.cdref.detectChanges()
          return of(chosenBulkDownload)
        }
      }),
      tap((chosen:any)=>{
        if(chosen?.getJobTitleProps().jobType === "BULK_DOWNLOAD"){
          chosen.addVideoEvent.next()
        }

      })
    )

  }

  listenForAddJob = ()=>{
    return this.props.addJobSubj
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((res)=>{
        return this.addJob(res)
      })
    )

  }

  listenForNewJobs = ()=>{
    return this.jobsService.listJobsDataSource.updateSourcesEvent
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((data)=>{
        let wasOnlyUpdate =data.every((updateObj)=>{
          return updateObj.configurations.every((config)=>{
            return config.action === "update"
          })
        })
        let videoAndJobWasAdded = data.some((updateObj)=>{
          return updateObj.configurations.some((config)=>{
            return config.action === "insert"
          }) && Boolean(updateObj.row?.details?.bulk_download_details?.videos)
        })
        let jobWasDeleted = data.some((updateObj)=>{
          return updateObj.configurations.some((config)=>{
            return config.action === "update" && updateObj.row.is_deleted === true
          })
        })


        if(!wasOnlyUpdate || jobWasDeleted){
          this.updateTitleBtn("global.saveBtnNotSaved")

          return this.jobsScrollObj.populateItemsAtStart({
            videoAndJobWasAdded
          })
        }
        else{
          return of(null)
        }
      })
    )

  }

  listenForUser = ()=>{
    return this.accountsService
    .onMapSocketIOSessionIdToUserEvent
    .pipe(
      tap(()=>{
        return this.jobsScrollObj.init()
      })
    )
  }

  saveJobs = ()=>{
    this.baseService.openOverlayLoading()
    return this.jobsService.listJobsDataSource.setSyncWithExternal(
      true,
      (res)=> of(res)
      .pipe(
        takeUntil(this.ngUnsub),
        filter((res)=>res === false),
        concatMap(()=>{
          return this.jobsService.listJobsDataSource.queryDataFromSource(new ListJobsUIRequestBody({
            pageSize:Infinity
          }))
        }),
        concatMap((res)=>{
          let jobs = res.data.map((job)=>{
            return new RunJobsEntity(job)
          })
          .filter((job)=>{
            // only get saved for receipinet since they both share the same sender id and sender should still always have the files
            return !['TRANSFER_LOCAL_FILES'].includes(job.jobType)
          })
          this.baseService.createWMLNote("JobsZero.WMLNotifyOne.cantSaveCertainJobs",WMLNotifyOneBarType.Info)
          return  this.jobsService.updateJobs(new UpdateJobsUIRequestBody({
            jobs,
          }))
        }),
        concatMap((res)=>{
          this.updateTitleBtn("global.saveBtn");
          return this.jobsScrollObj.populateItemsAtStart()
        }),
        this.baseService.closeOverlayLoadingViaRxjsFinalize
      )
    )
  }

  startJob = (id,card:JobsZeroPropsCard)=>{
    if([].includes(ENV.type)){
      this.baseService.openFeatureIsComingSoon()
      return of(null)
    }
    if(this.accountsService.currentUser.googleDriveApiAccessToken === null){
      this.baseService.createWMLNote("JobsZero.WMLNotifyOne.mustSelectAStoragePlatform",WMLNotifyOneBarType.Error)
      return of(null)
    }
    let job = this.jobsService.listJobsDataSource.sources["[[],[]]"].data.find((job)=>job.id === id)
    if(job.job_type === "SHORTS_GENENERATION"){
      this.baseService.openFeatureIsComingSoon()
      return of(null)
    }
    if(job.job_type === "BULK_DOWNLOAD" && job.details.bulk_download_details.videos.length <= 0){
      this.baseService.createWMLNote("JobsZero.WMLNotifyOne.mustAddVideosToJob",WMLNotifyOneBarType.Error)
      return of(null)
    }
    if(job.status === "SUCCESS" && job.result_urls.length > 0){
      this.baseService.createWMLNote("JobsZero.WMLNotifyOne.preventTheRunOfSucessfulJobsWithLinks",WMLNotifyOneBarType.Error)
      return of(null)
    }
    if(job.is_deleted === true){
      this.baseService.createWMLNote("JobsZero.WMLNotifyOne.jobIsDeleted",WMLNotifyOneBarType.Error)
      return of(null)
    }
    this.baseService.createWMLNote("global.WMLNotifyOne.ensureEnoughStorage",WMLNotifyOneBarType.Info)
    return this.jobsService.listJobsDataSource.updateSources([
      new WMLDataSourceUpdateSourcesObj({
        row:{
          status:"PENDING"
        },
        id,
        configurations:[{
          action:"update"
        }]
      })
    ])
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap(()=>{

        let newJob = new RunJobsEntity(transformObjectKeys(job,transformFromSnakeCaseToCamelCase))
        let reqBody =new RunJobsUIRequestBody({
          jobs:[newJob]
        })
        return this.jobsService.runJobs(reqBody)
      }),
    )
  }

  listJobs:JobsZeroPropsCard[] = []
  jobsScrollObj = new ScrollPageProps({
    targetElement:"."+this.classPrefix('Pod1'),
    dataSource:this.jobsService.listJobsDataSource,
    ngUnsub:this.ngUnsub,

    manualInit:true,
    onItemsErrorListener:(err)=>{
      console.error(err)
      this.baseService.createWMLNote("JobsZero.WMLNotifyOne.errorRetrievingJobs",WMLNotifyOneBarType.Error)
    },
    // TODO might be easier to popoulate from this.jobsService.listJobsDataSource.currentSource.data cant right now because of scroll logic
    onItemsRetrievedListener:(res,misc)=>{

      this.listJobs.forEach((card)=>{
        card.ngUnsub.next()
      })
      let transformedJobs = transformObjectKeys(
        this.jobsService.listJobsDataSource.currentSource.data,
        transformFromSnakeCaseToCamelCase
      )

      let currentJobs = transformedJobs.filter((job)=>{
        return !job.isDeleted
      })
      let {pageNum,pageSize}= this.jobsScrollObj.pageReqBody
      currentJobs = currentJobs.slice(0,(pageNum+1)*pageSize)

      let cards:JobsZeroPropsCard[] = currentJobs.map((job:RunJobsEntity,index0)=>{

        let card:JobsZeroPropsCard =  new JobsZeroPropsCard({
          id:job.id,
          jobType:job.jobType,
          startJobBtn :new WMLButtonOneProps({
            isPresent:(["BULK_DOWNLOAD",
              "SHORTS_GENENERATION"].includes(job.jobType)),
            type:WMLButtonPropsTypeEnum.TERTIARY,
            text:"JobsZero.startJobBtn",
            click:()=>this.startJob(job.id,card).subscribe()
          }),
          deleteJobBtn: new WMLButtonOneProps({
            type:WMLButtonPropsTypeEnum.TERTIARY,
            text:"JobsZero.deleteJobBtn",
            click:()=>this.deleteJob(job.id,card).subscribe()
          }),
          ngUnsub:new Subject<void>()
        })
        let accordionBody = new WMLCustomComponent(card.props)
        let isOpen = job.status === "IN_PROGRESS" || index0 ===0
        let item = new WMLAccordionZeroItemProps({
          isClosed:!isOpen,
          accordionTitle:new WMLCustomComponent({
            cpnt:JobTitleZeroComponent,
            props:new JobTitleZeroProps({
              jobType:job.jobType,
              jobIsChosen:index0 === 0
            })
          }),
          accordionBody
        })
        card.card = new WMLAccordionZeroProps({
          items:[[item]]
        })


        accordionBody.props.jobInfo.status = job.status
        accordionBody.props.jobInfo.resultURL = job.resultUrls?.[0]

        if(job.jobType ==="BULK_DOWNLOAD"){


          accordionBody.props.items = job.details?.bulkDownloadDetails?.videos?.map((video)=>{
            return new DownloadJobZeroItemProps({
              title:video.title,
              downloadUrl:video.downloadUrl,
              fileSize:video.fileSize,
              thumbnailUrl:video.thumbnailUrl
            })
          }) ?? []
        }

        else if(job.jobType ==="BACKUP_PREVIOUS_STATE"){
          // KEEP For Future
        }

        else if(job.jobType ==="UPDATE_VIDEOS"){
          accordionBody.props.changedVideos = job.details?.updateVideosDetails?.videos ?? []
        }

        else if(job.jobType ==="TRANSFER_LOCAL_FILES"){
          Object.assign(accordionBody.props,job.details?.transferLocalFilesDetails ?? {})

        }

        this.listenForSelectJob(item).subscribe()
        this.listenForJobTypeChange(card).subscribe()
        this.listenForJobUpdate(card).subscribe()

        return card

      })

      this.listJobs = cards
      if(ENV.app.toPassChromeOauthVerficationTest){
        this.listJobs = this.listJobs.filter((item)=>{
          return item.jobType !== "BULK_DOWNLOAD"
        })
      }

      if(misc?.videoAndJobWasAdded && this.listJobs[0]?.jobType === "BULK_DOWNLOAD"){
        this.listJobs[0]?.addVideoEvent.next()
      }

      this.cdref.detectChanges()

    }
  })

  titleCard = new CardOneProps({
    title:"JobsZero.title",
    customBody:null,
    titleBtn:new WMLButtonOneProps({
      text:"global.saveBtn",
      click:()=>{
        if(this.titleCard.titleBtn.text === "global.saveBtnNotSaved"){

          this.saveJobs().subscribe()
        }

      }
    })
  })

  addjobBtn = new WMLButtonOneProps({
    text:"JobsZero.addJobBtn",
    click:()=>this.addJob().subscribe()
  })

  clearFinishedAndFailedJobsBtn = new WMLButtonOneProps({
    text:"JobsZero.clearFinishedAndFailedJobsBtn",
    click:this.baseService.openFeatureIsComingSoon
  })


  updateTitleBtn(textValue) {
    this.titleCard.titleBtn.text = textValue;
    this.titleCard.titleBtn.updateSubj.next(this.titleCard.titleBtn);
  }

  ngOnInit(): void {
    this.listenForUser().subscribe()
    this.listenForFinishedJobs().subscribe()
    this.listenForAddVideo().subscribe()
    this.listenForNewJobs().subscribe()
    this.listenForAddJob().subscribe()
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

@WMLConstructorDecorator
export class JobsZeroProps {
  constructor(props:Partial<JobsZeroProps>={}){}
  close:Function
  addVideosSubj = new Subject<RunJobsEntity>()
  addJobSubj = new Subject<RunJobsEntity>()
}


export class JobsZeroPropsCard {
  constructor(props: Partial<JobsZeroPropsCard> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  id: string;
  card: WMLAccordionZeroProps;
  startJobBtn: WMLButtonOneProps;
  deleteJobBtn: WMLButtonOneProps;
  ngUnsub = new Subject<void>();
  addVideoEvent = new Subject<void>();
  selectJobTypeEvent = new Subject<void>();
  jobType:ListJobsEntity["jobType"]

  getAccordionCpnt(){
    return this.card.items[0][0]
  }
  getJobTitleProps(): JobTitleZeroProps {
    return this.getAccordionCpnt().accordionTitle.props;
  }
  readonly donwloadJobZeroProps =new DownloadJobZeroProps()
  readonly aiShortsJobZeroProps =new AiShortsJobZeroProps()
  readonly backupPreviousStateJobZeroProps = new BackupPreviousStateJobZeroProps()
  readonly updateVideosJobZeroProps = new UpdateVideosJobZeroProps({
    jobInfo:new JobStatusZeroProps({
      resultURLIsPresent:false
    })
  })
  readonly transferLocalFilesJobZeroProps = new TransferLocalFilesJobZeroProps({
    jobInfo:new JobStatusZeroProps({
      resultURLIsPresent:false
    })
  })

  get props(){
    return {
      "BULK_DOWNLOAD": {
        cpnt: DownloadJobZeroComponent,
        props: this.donwloadJobZeroProps
      },
      "SHORTS_GENENERATION": {
        cpnt: AiShortsJobZeroComponent,
        props: this.aiShortsJobZeroProps
      },
      "BACKUP_PREVIOUS_STATE":{
        cpnt: BackupPreviousStateJobZeroComponent,
        props: this.backupPreviousStateJobZeroProps
      },
      "UPDATE_VIDEOS":{
        cpnt: UpdateVideosJobZeroComponent,
        props: this.updateVideosJobZeroProps
      },
      "TRANSFER_LOCAL_FILES":{
        cpnt: TransferLocalFilesJobZeroComponent,
        props: this.transferLocalFilesJobZeroProps
      }
    }[this.jobType] as {
      cpnt:Type<any>,
      props:JobsZeroCommonProps
    }
  }
}
