<div *ngIf="props?.current !== null" [class]="classPrefix('MainPod')">
  <div [class]="classPrefix('MainItem0')">
    <img
    [class]="classPrefix('MainImg0')"
    (error)=props.current.videoThumbnail.onError?.($event) [src]=props.current.videoThumbnail.src [alt]="props.current.videoThumbnail.alt | translate" />
    <section [class]="classPrefix('Pod0')">
      <div  [class]="classPrefix('Pod0Item2')">
        <ng-container >
          <div
          *ngIf="platformsService.videoDataEditorIsModified"
          [class]="classPrefix('Pod0Item3')" >
            <wml-button-one  [props]="saveBtn"></wml-button-one>
            <wml-button-one  [props]="updateVideosBtn"></wml-button-one>
          </div>
          <div [class]="classPrefix('Pod0Item3')" >
            <wml-button-one *ngIf="platformsService.videoDataEditorIsModified"  [props]="backupPreviousStateBtn"></wml-button-one>

          </div>
        </ng-container>
      </div>
      <div [class]="classPrefix('Pod0Item0')">
        <i
        (click)="goToPrevPage()"
        [class]=" classPrefix('Pod0Icon0') +' fa-solid fa-chevron-left'"></i>
        <page-number-control-zero
        [class]="classPrefix('Pod0Item1')"
        [props]="targetVideosControl"></page-number-control-zero>
        <i
        (click)="goToNextPage()"
        [class]="classPrefix('Pod0Icon0') + 'fa-solid fa-chevron-right'"></i>
      </div>

    </section>
    <section [class]="classPrefix('Pod1')">
      <div [class]="classPrefix('Pod1Item0')">
        <div [class]="classPrefix('Pod1Item4')">
          <img
          (click)="openEditor(VideoDataEditorZeroTypeEnum.THUMBNAIL)"
          [class]="classPrefix('Pod1Img0')"
          (error)=props.current.videoThumbnail [src]=props.current.videoThumbnail.src [alt]="props.current.videoThumbnail.alt | translate" />
          <wml-button-one
          [class]="classPrefix('Pod1Btn1')"
          [props]="viewVideoBtn"
          ></wml-button-one>
        </div>

        <div [class]="classPrefix('Pod1Item1')">
          <div>
            <h5 [class]="classPrefix('Pod1Title1')">{{props.current.platformTitle}}</h5>
            <h4
            >{{props.current.channelTitle}}</h4>
            <h3
            [class]="classPrefix('Pod1Title1')"
            *ngIf="props.current.playlistTitle !== 'global.na'">{{props.current.playlistTitle}}</h3>
            <!-- like this pre will space accoding to the spacing you give -->
            <h2 (click)="openEditor(VideoDataEditorZeroTypeEnum.TITLE)"
            [class]="classPrefix('Pod1Title0')"><pre>{{props.displayTitle[0] }} <span [class]="classPrefix('Pod1Title1')" >{{props.displayTitle[1]}}</span></pre></h2>

          </div>
          <div>
            <div
            (click)="this.openEditor(VideoDataEditorZeroTypeEnum.TAGS)"
            [class]="classPrefix('Pod1Item2')">
              <h6>{{"VideoDataEditorPage.tags" | translate}}</h6>
              <div [class]="classPrefix('Pod1StrikeThru0')"></div>
              <div [class]="classPrefix('Pod1Item3')">
                <wml-button-one
                [class]="classPrefix('Pod1Btn0')"
                [props]="btn" *ngFor="let btn of props.displayTags"></wml-button-one>
              </div>
            </div>
          </div>

        </div>
      </div>

    </section>
    <section
    (click)="this.openEditor(VideoDataEditorZeroTypeEnum.DESCRIPTION)"
    [class]="classPrefix('Pod2')">
      <div [class]="classPrefix('Pod2Item0')">
        <h3>{{"VideoDataEditorPage.desc" | translate}}</h3>
      </div>
      <pre [class]="classPrefix('Pod2Text0')">{{props.current.description}}</pre>
    </section>

  </div>
</div>
