// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService, BaseServiceSubmitFormProps } from '@core/base/base.service';

// rxjs
import { concatMap, defer, distinctUntilChanged, forkJoin, iif, map, merge, of, skip, Subject, Subscription, take, takeUntil, tap } from 'rxjs';

// wml-components
import { generateClassPrefix, generateIdPrefix,WMLUIProperty } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { SharedModule } from '@shared/shared.module';
import { VideoTileZeroProps } from '@shared/components/video-tile-zero/video-tile-zero.component';
import { getVideoDataEditorData } from '@shared/components/video-tile-zero/functions';
import { WMLButtonPropsTypeEnum, WMLButtonOneProps } from '@windmillcode/angular-wml-button';
import { PageNumberControlZeroProps } from '@shared/components/page-number-control-zero/page-number-control-zero.component';
import { WMLCustomComponent } from '@windmillcode/wml-components-base';
import { VideoViewerZeroComponent, VideoViewerZeroProps } from '@shared/components/video-viewer-zero/video-viewer-zero.component';
import { VideoDataEditorZeroComponent, VideoDataEditorZeroProps,  } from '@shared/components/video-data-editor-zero/video-data-editor-zero.component';
import {PlatformsVideoDataEditorZeroSectionEnum,PlatformsVideoDataEditorZeroActionEnum, PlatformsService} from "@shared/services/platforms/platforms.service"
import { WMLDataSourceWebStorageEntity } from '@core/utility/data-source-utils';
import { FormsService } from '@shared/services/forms/forms.service';
import { SpecificService } from '@core/specific/specific.service';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';
import { saveUserVideoDataEditorSettingsTransformViaFormGroupValue, SaveUserVideoDataEditorSettingsUIRequestBody } from '@shared/services/platforms/saveUserVideoDataEditorSettings';
import { TextEditor, transformFromSnakeCaseToCamelCase } from '@core/utility/string-utils';
import { deepCopy, isMissingValue, transformObjectKeys } from '@core/utility/common-utils';
import { LoadUserVideoDataEditorSettingsUIResponseBody } from '@shared/services/platforms/loadUserVideoDataEditorSettings';
import localforage from 'localforage';
import { RunJobsEntity, RunJobsUIRequestBody } from '@shared/services/jobs/runJobs';
import { JobsService } from '@shared/services/jobs/jobs.service';
import { readFileContent } from '@core/utility/file-utils';
import { ListJobsEntity } from '@shared/services/jobs/listJobs';
import { deepCopyInclude } from '@core/utility/object-utils';
import { SocketioService } from '@shared/services/socketio/socketio.service';
import { NEED_NEW_TOKEN } from '@app/core/utility/constants';


@Component({
  selector: 'video-data-editor-page',
  templateUrl: './video-data-editor-page.component.html',
  styleUrls: ['./video-data-editor-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
  ]
})
export class VideoDataEditorPageComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService,
    public formsService:FormsService,
    public platformsService:PlatformsService,
    public specificService:SpecificService,
    public jobsService:JobsService,
    public socketioService:SocketioService,
  ) { }

  classPrefix = generateClassPrefix('VideoDataEditorPage')
  idPrefix = generateIdPrefix(ENV.idPrefix.videoDataEditorPage)
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  props:VideoDataEditorPageProps
  targetVideosControl = new PageNumberControlZeroProps({})
  viewVideoBtn = new WMLButtonOneProps({
    text:"global.view",
    type:WMLButtonPropsTypeEnum.TERTIARY,
    click:()=>this.openViewer()
  })
  VideoDataEditorZeroTypeEnum= PlatformsVideoDataEditorZeroSectionEnum
  saveBtn = new WMLButtonOneProps({
    text:this.platformsService.videoDataEditorSaved,
    click:()=>this.save()
  })

  updateVideosBtn = new WMLButtonOneProps({
    text :"VideoDataEditorPage.updateVideosBtn",
    click:()=>this.updateVideos().subscribe()
  })

  backupPreviousStateBtn = new WMLButtonOneProps({
    text :"VideoDataEditorPage.backup",
    click:()=>this.backupPreviousState().subscribe()
  })

  save = ()=>{
    if(this.saveBtn.text ==="global.saveBtn"){
      return
    }
    let editorFormGroup = this.formsService.videoDataEditorZero.mainForm
    let value = {
      ...editorFormGroup.value,
      videos:this.props.items
    }
    this.baseService.createWMLNote("VideoDataEditorPage.WMLNotifyOne.noThumbnailsSaved",WMLNotifyOneBarType.Info)

    return this.baseService.submitForm(new BaseServiceSubmitFormProps({
      rootFormGroup:editorFormGroup,
      cdref:this.cdref,
      validateForm:false,
      validFormPredicateTypeDefault: {
        ngUnsub: this.ngUnsub,
        submitFormSuccessResetForm:false,
        // @ts-ignore
        apiCall$: this.platformsService.saveUserVideoDataEditorSettings(value)
        .pipe(
          takeUntil(this.ngUnsub),
          tap((res)=>{
            if(res=== NEED_NEW_TOKEN){
              this.specificService.reconnectToAPlatform()
            }
            else{
              this.updateSaveBtn("global.saveBtn");
            }
          })
        )

        ,
      },

    }))
  }

  updateVideos = ()=>{
    let editorFormGroup = this.formsService.videoDataEditorZero.mainForm

    let targetVideos = this.props.items
    .filter((item)=>{
      return editorFormGroup.value.videos.find((video)=>video.value.item.appId === item.appId)
    })
    .map((item)=>{
      return {
        actionTypeEnumValue:editorFormGroup.value.action[0].value,
        sectionTypeEnumValue:editorFormGroup.value.type[0].value,
        platform:item.platformTitle,
        id:item.videoId,
        description:item.description,
        tags:item.tags,
        thumbnail:item.videoThumbnail.value,
        title:item.videoTitle
      }
    })
    let jobId = this.jobsService.getIdForNewItemInListJobsDataSource()
    // console.log(targetVideos)
    if(targetVideos.length ===0){
      this.baseService.createWMLNote("VideoDataEditorZero.WMLNotifyOne.noChangesInDataEditor",WMLNotifyOneBarType.Error)
      return of(null)
    }
    this.platformsService.videoDataEditorIsModified = false
    const thumbnailReadObservables = targetVideos.map((video, index) =>{
      // @ts-ignore
      if(video.thumbnail ===""){
        return of(null)
      }
      return readFileContent(video.thumbnail, "readAsBinaryString")
      .pipe(
        take(1),
        tap(res => {
          targetVideos[index].thumbnail = {
            name: video.thumbnail.name,
            content: res.content,
            type: video.thumbnail.type
          };
        })
      )
    });
    return forkJoin(thumbnailReadObservables)
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        this.jobsService.jobPanelItem.custom.props.addJobSubj.next(
          new ListJobsEntity({
            id:jobId,
            jobType: 'UPDATE_VIDEOS',
            details: {
              updateVideosDetails: {
                videos: targetVideos.map((video)=>{
                  return deepCopyInclude([
                    "title",
                    "actionTypeEnumValue",
                    "sectionTypeEnumValue",
                    // TODO may provide an actual property here newValue get populated once the job complete
                    "newValue",
                  ],video)
                }),
              },
            },
          }),
        );
        this.jobsService.jobPanelItem.open();

      }),
      map(()=>{
        let job= new RunJobsEntity({
          id:jobId,
          status:"NOT_STARTED",
          priority:"LOW",
          jobType:"UPDATE_VIDEOS",
          details:{
            updateVideosDetails:{
              videos:targetVideos
            }
          }
        })
        let reqBody = new RunJobsUIRequestBody({
          jobs:[job]
        })
        return reqBody
      }),
      concatMap((reqBody)=>{
        this.platformsService.videoDataEditorIsModified = false
        return this.jobsService.runJobs(reqBody)
      }),
      tap({
        error:()=>{
          this.platformsService.videoDataEditorIsModified = true
          this.cdref.detectChanges()
        }
      })
    )

  }

  backupPreviousState=()=>{

    let videoIds = this.props.items.map((item)=>{
      return {
        id:item.videoId,
        platformTitle:item.platformTitle
      }
    })
    let jobId = this.jobsService.getIdForNewItemInListJobsDataSource()
    this.baseService.createWMLNote("global.WMLNotifyOne.ensureEnoughStorage",WMLNotifyOneBarType.Warning)

    let job= new RunJobsEntity({
      id:jobId,
      status:"NOT_STARTED",
      priority:"LOW",
      jobType:"BACKUP_PREVIOUS_STATE",
      details:{
        backupPreviousStateDetails:{
          videoIds
        }
      }
    })
    let reqBody = new RunJobsUIRequestBody({
      jobs:[job]
    })
    return of(null)
    .pipe(
      tap(()=>{
        this.jobsService.jobPanelItem.custom.props.addJobSubj.next(
          new ListJobsEntity({
            id:jobId,
            jobType: 'BACKUP_PREVIOUS_STATE',
            details: {
              backupPreviousStateDetails: {},
            },
          })
        );
        this.jobsService.jobPanelItem.open();
      }),
      concatMap(()=>{
        this.platformsService.videoDataEditorIsModified = false
        return this.jobsService.runJobs(reqBody)
      }),
      tap({
        error:()=>{
          this.platformsService.videoDataEditorIsModified = true
          this.cdref.detectChanges()
        }
      }),
      takeUntil(this.ngUnsub)
    )

  }

  listenForFinishedJobs = ()=>{
    return merge(
      this.socketioService.backupPreviousStateEvent,
      this.socketioService.updateVideosEvent,
    )
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this.platformsService.videoDataEditorIsModified = res.data.result.after_job_info.some((job)=>{
          return ["ISSUES","FAILED"].includes(job.status)
        })
        this.cdref.detectChanges()
      })
    )

  }

  getChosenVideos = ()=>{

    return iif(
      ()=>this.platformsService.videoDataEditorSettings,
      of(this.platformsService.videoDataEditorSettings),
      getVideoDataEditorData()
    )
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this.props  = res
        this.props.current = this.props.items[0]

        this.updateCurrentTarget();
      })
    )

  }

  decorateTitle =()=>{
    let words = this.props.current.videoTitle.split(/ +(?=\S)/)
    let wordToStyle = words.pop()
    return [words.join(" "),wordToStyle]

  }

  updateCurrentTarget= (updateTargetVideosControl=true)=> {
    let {current} = this.props
    this.props.displayTitle = this.decorateTitle();
    current.videoThumbnail.src = isMissingValue(current.videoThumbnail.src,this.specificService.logoImg.src)
    if(updateTargetVideosControl){
      this.targetVideosControl.items = this.props.items.map((item)=>{
        return new WMLUIProperty({
          text:item.videoTitle,
          value:item.appId
        })
      })
      // like this so angular can detect and update page-number-zero component
      this.cdref.detectChanges()
      this.targetVideosControl.updateSubj.next(this.targetVideosControl)
    }

    this.props.displayTags = current.tags?.map((item)=>{
      return new WMLButtonOneProps({
        text:item,
        click:()=>this.openEditor(PlatformsVideoDataEditorZeroSectionEnum.TAGS)
      })
    }) ?? []

    this.cdref.detectChanges()
  }

  selectVideo = ()=>{
    return this.targetVideosControl.pageNumChangeEvent
    .pipe(
      skip(1),
      takeUntil(this.ngUnsub),
      distinctUntilChanged(),
      tap((appId)=>{
        let index = this.props.items.findIndex((item)=>{
          return item.appId === appId
        })
        this.props.current = this.props.items[index]
        this.updateCurrentTarget(false)
        this.cdref.detectChanges()
      })
    )

  }

  getCurrentPageGroup = () => {
    let { currentPage, amntOfPagesToShow } = this.targetVideosControl;
    let currentPageGroup = Math.floor(currentPage / amntOfPagesToShow) * amntOfPagesToShow;
    return currentPageGroup;
  }

  goToPrevPage = () => {
    let { currentPage, amntOfPagesToShow, items } = this.targetVideosControl;
    let totalPages = items.length;
    let currentPageGroup = this.getCurrentPageGroup();
    if (currentPageGroup === 0) {
        // If at the first group, jump to the last group
        let lastPageGroupStart = Math.floor((totalPages - 1) / amntOfPagesToShow) * amntOfPagesToShow;

        this.targetVideosControl.currentPage = lastPageGroupStart;
    } else {
        // Move to the first page of the previous page group
        this.targetVideosControl.currentPage = currentPageGroup - amntOfPagesToShow;
    }
    this.targetVideosControl.updateSubj.next(this.targetVideosControl);
  }

  goToNextPage = () => {
    let { currentPage, amntOfPagesToShow, items } = this.targetVideosControl;
    let totalPages = items.length;
    let currentPageGroup = this.getCurrentPageGroup();
    let nextPageGroupStart = currentPageGroup + amntOfPagesToShow;

    if (nextPageGroupStart >= totalPages) {
        // If trying to go beyond the last page, jump back to the first page group
        this.targetVideosControl.currentPage = 0;
    } else {
        this.targetVideosControl.currentPage = nextPageGroupStart;
    }
    this.targetVideosControl.updateSubj.next(this.targetVideosControl);
  }

  openViewer = ()=>{

    this.baseService.openPopup(new WMLCustomComponent({
      cpnt:VideoViewerZeroComponent,
      props:new VideoViewerZeroProps({
        videoSrc:this.props.current.videoPlayer.src
      })
    }))
  }

  openEditor=(type:PlatformsVideoDataEditorZeroSectionEnum)=>{
    let props = new VideoDataEditorZeroProps({
      type:this.loadedEditorSettings?.sectionTypeEnumValue ?? type,
      propItems:this.props.items,
      current:this.props.current,
      settings:this.loadedEditorSettings
    })
    // delete the editor settings after retrieved to use the formControl
    delete this.loadedEditorSettings
    this.listenForResultsSub = this.listenForResults(props).subscribe()
    this.baseService.openPopup(new WMLCustomComponent({
      cpnt:VideoDataEditorZeroComponent,
      props
    }))
  }

  listenForResultsSub:Subscription
  listenForResults = (props:VideoDataEditorZeroProps)=>{
    this.listenForResultsSub?.unsubscribe()
    return props.previewChangeSubj
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((uiBody)=>{
        this.platformsService.videoDataEditorIsModified = true
        // @ts-ignore
        uiBody.videos = uiBody.videos.map((video)=>video.value.item)
        // @ts-ignore
        let apiBody = saveUserVideoDataEditorSettingsTransformViaFormGroupValue(uiBody,false)
        let result = new SaveUserVideoDataEditorSettingsUIRequestBody(transformObjectKeys(apiBody.data,transformFromSnakeCaseToCamelCase))
        let thumbnailCounter = 0
        this.props.items = this.props.items.map((item)=>{

          if(!result.videos.includes(item.appId)){
            return item
          }
          let videoTileKey = {
            [PlatformsVideoDataEditorZeroSectionEnum.TITLE]:"videoTitle",
            [PlatformsVideoDataEditorZeroSectionEnum.TAGS]:"tags",
            [PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION]:"description",
            [PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL]:"videoThumbnailSrc",
          }[result.sectionTypeEnumValue]
          let editorKey = {
            [PlatformsVideoDataEditorZeroSectionEnum.TITLE]:"title",
            [PlatformsVideoDataEditorZeroSectionEnum.TAGS]:"tags",
            [PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION]:"description",
            [PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL]:"thumbnail",
          }[result.sectionTypeEnumValue]
          if(result.actionTypeEnumValue === PlatformsVideoDataEditorZeroActionEnum.PREPEND){
            if([
              PlatformsVideoDataEditorZeroSectionEnum.TITLE,
              PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION,
            ].includes(result.sectionTypeEnumValue)){
              item[videoTileKey] = result.content[editorKey] +item[videoTileKey]
            }
            else if([
              PlatformsVideoDataEditorZeroSectionEnum.TAGS,
            ].includes(result.sectionTypeEnumValue)){
              item[videoTileKey].unshift(...result.content[editorKey])
            }
          }
          if(result.actionTypeEnumValue === PlatformsVideoDataEditorZeroActionEnum.APPEND){
            if([
              PlatformsVideoDataEditorZeroSectionEnum.TITLE,
              PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION,
            ].includes(result.sectionTypeEnumValue)){
              item[videoTileKey] = item[videoTileKey] + result.content[editorKey];
            }
            else if([
              PlatformsVideoDataEditorZeroSectionEnum.TAGS,
            ].includes(result.sectionTypeEnumValue)){
              item[videoTileKey] = [...item[videoTileKey], ...result.content[editorKey]];
            }
          }
          if(result.actionTypeEnumValue === PlatformsVideoDataEditorZeroActionEnum.OVERWRITE){
            if([
              PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL
            ].includes(result.sectionTypeEnumValue)){

              let targetThumbnail = result.content.thumbnail[Math.min(thumbnailCounter,result.content.thumbnail.length-1)].file
              let imgThumbnail = URL.createObjectURL(targetThumbnail)
              item[videoTileKey] = imgThumbnail
              item.videoThumbnail.value = targetThumbnail
              thumbnailCounter+=1
            }
            else{
              item[videoTileKey] = deepCopy(result.content[editorKey]);
            }
          }
          if(result.actionTypeEnumValue === PlatformsVideoDataEditorZeroActionEnum.INSERT){
            if([
              PlatformsVideoDataEditorZeroSectionEnum.TITLE,
              PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION,
            ].includes(result.sectionTypeEnumValue)){
              let textEditor = new TextEditor({ text: item[videoTileKey] });
              let {insertLine, insertChar} = result.insert;
              // debugger
              textEditor.insert(insertLine.val-1, insertChar.val-1, result.content[editorKey]);
              item[videoTileKey] = textEditor.text;
            }
            else if([
              PlatformsVideoDataEditorZeroSectionEnum.TAGS,
            ].includes(result.sectionTypeEnumValue)){
              let { insertChar } = result.insert;
              item[videoTileKey].splice(insertChar.val, 0, ...result.content[editorKey]);
            }
          }
          if(result.actionTypeEnumValue === PlatformsVideoDataEditorZeroActionEnum.REPLACE){
            let { fromLine, fromChar, toLine, toChar } = result.replace;
            if([
              PlatformsVideoDataEditorZeroSectionEnum.TITLE,
              PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION,
            ].includes(result.sectionTypeEnumValue)){
              let textEditor = new TextEditor({ text: item[videoTileKey] });
              textEditor.replace(fromLine.val-1, fromChar.val-1, toLine.val-1, toChar.val-1, result.content[editorKey]);
              item[videoTileKey] = textEditor.text;
            }
            else if([
              PlatformsVideoDataEditorZeroSectionEnum.TAGS,
            ].includes(result.sectionTypeEnumValue)){
              item[videoTileKey].splice(fromChar.val-1, toChar.val - fromChar.val, ...result.content[editorKey]);
            }
          }
          return item
        })
        this.props.current = this.props.items.find((item)=>{

          return item.appId === this.props.current.appId
        })
        this.updateCurrentTarget()

        return getVideoDataEditorData()
        .pipe(
          takeUntil(this.ngUnsub),
          tap((userEditorData)=>{
            userEditorData.items = this.props.items
            this.platformsService.videoDataEditorSettings = userEditorData
            return defer(async ()=> {
              userEditorData.items =JSON.parse(JSON.stringify(this.props.items))
              await localforage.setItem(ENV.localForage.VideoDataEditor,
                userEditorData
              )

            })
          })
        )
      })
    )

  }

  listenForEditorChanges = ()=>{
    return this.formsService.contentEditorControlZero.mainForm.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        this.updateSaveBtn("global.saveBtnNotSaved");
      })
    )

  }


  loadedEditorSettings = new LoadUserVideoDataEditorSettingsUIResponseBody()
  loadEditorSettings = ()=>{
    this.baseService.openOverlayLoading()
    return this.platformsService.loadUserVideoDataEditorSettings()
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res:any)=>{

        this.loadedEditorSettings = res
      }),
      this.baseService.closeOverlayLoadingViaRxjsFinalize,
      concatMap(()=>{
        return merge(
          this.getChosenVideos(),
          this.selectVideo(),
          this.listenForEditorChanges(),
        )
      })
    )

  }

  updateSaveBtn(value) {
    this.saveBtn.text = this.platformsService.videoDataEditorSaved = value;
    this.saveBtn.updateSubj.next(this.saveBtn);
  }

  ngAfterViewInit(): void {
    this.loadEditorSettings().subscribe()
    this.listenForFinishedJobs().subscribe()

  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}


export class VideoDataEditorPageProps extends WMLDataSourceWebStorageEntity {
  constructor(props: Partial<VideoDataEditorPageProps> = {}) {
    super()
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  items:VideoTileZeroProps[]=[]
  current:VideoTileZeroProps
  displayTitle:string[]
  displayTags:WMLButtonOneProps[] =[]
  override isSyncedWithExternal: any = false
}

