// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'insert-at-control-zero',
  templateUrl: './insert-at-control-zero.component.html',
  styleUrls: ['./insert-at-control-zero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class InsertAtControlZeroComponent {
  classPrefix = generateClassPrefix('InsertAtControlZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.insertAtControlZero);
  @Input('props') props: InsertAtControlZeroProps = new InsertAtControlZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();

  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService
  ) { }

  listenForUpdate = () => {
    return this.props.updateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new InsertAtControlZeroProps({
          ...this.props,
          ...(res ?? this.props)
        });
        this.cdref.detectChanges();
      })
    );
  };

  ngOnInit(): void {

    this.listenForUpdate().subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

export class InsertAtControlZeroProps {

  constructor(props: Partial<InsertAtControlZeroProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key, val]) => !key.startsWith('prop'));
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  updateSubj = new Subject<InsertAtControlZeroProps>();
  wmlField:WMLFieldZeroProps
  insertText:string
  ofText= "global.of"
  get formGroup(){
    return this.wmlField.getReactiveFormControl() as FormGroup<{
      val:FormControl<number>,max:FormControl<number>
    }>
  }
}
