// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';


// misc
import { ENV } from '@env/environment';
import { ScrollPageProps } from '@core/utility/scroll-utils';
import { WMLButtonOneProps } from '@windmillcode/angular-wml-button';



@Component({
    selector: 'filter-zero',
    templateUrl: './filter-zero.component.html',
    styleUrls: ['./filter-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilterZeroComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
  ) {}

  classPrefix = generateClassPrefix('FilterZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.filterZero);
  @Input('props') props: FilterZeroProps = new FilterZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();

  listenForNewItems =()=>{
    return this.props.updateItemsSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        res = res.map((item)=>{
          item.click = ()=>this.toggleOption(item)
          return item
        })
        this.props.items.push(...res)
        this.cdref.detectChanges()
      })
    )

  }

  toggleOption = (item:WMLButtonOneProps)=>{
    item.updateBtnClassString(
      "WMLButtonOneMainButton0Selected0","toggle"
    )
    this.props.filterEvent.next({
      value:item.value,
      filter:item.btnClass.includes("WMLButtonOneMainButton0Selected0")
    })
    this.cdref.detectChanges()
  }

  ngOnInit(): void {
    this.myId = this.props.id ?? this.myId;
    this.listenForNewItems().subscribe()
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}



export class FilterZeroProps {
  constructor(props:Partial<FilterZeroProps>={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }
  id:string
  items:WMLButtonOneProps[] = Array(5)
  .fill(null)
  .map((nullVal,index0)=>{
    return new WMLButtonOneProps({
      text:"Item "+index0,
    })
  })
  title:string
  filterEvent = new Subject<{
    value:string,
    filter:boolean
  }>()
  updateItemsSubj = new Subject<FilterZeroProps["items"]>()
  scrollObj:ScrollPageProps
}


