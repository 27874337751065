import { ApplicationConfig, ErrorHandler, provideAppInitializer } from '@angular/core';
import { Router, TitleStrategy, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ENV } from '@env/environment';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpLoaderFactory, I18NSEOStrategy, waitFori18nextToLoad } from '@core/utility/i18n-utils';
import { TranslateLoader, provideTranslateService } from '@ngx-translate/core';
import { GlobalErrorHandler } from '@shared/errorhandlers/global-error-handler';
import { NewAzureAccessTokenInterceptor } from '@shared/interceptors/new-azure-access-token.interceptor';
import { XsrfInterceptor } from '@shared/interceptors/xsrf.interceptor';
import Quill from 'quill';
import {TextAreaEmoji }from "@windmillcode/quill-emoji";
import { findMaxArrayBuffer } from '@core/utility/file-utils';
import {init,browserTracingIntegration,replayIntegration,TraceService } from "@sentry/angular-ivy";

findMaxArrayBuffer()

// Quill.register('modules/emoji-shortname', ShortNameEmoji,true)
Quill.register('modules/emoji-textarea', TextAreaEmoji,true)
gsap.registerPlugin(ScrollToPlugin);

if(!["DEV","TEST","DOCKER_DEV"].includes(ENV.type)){
  init({
    environment:"Angular_"+ENV.type.toUpperCase(),
    dsn: "https://48d21c6e608f2d99b006139b08537593@o4505122556215296.ingest.sentry.io/4506533270061056",
    integrations: [
      browserTracingIntegration({
        tracePropagationTargets: ["localhost", "https://eneobia.com","https://ui.preview.eneobia.com"],
      }),
      replayIntegration()
    ],
    // beforeSend

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: ["DEV","TEST","DOCKER_DEV"].includes(ENV.type)? 0.0: 1.0,
  });

}

let routerProviders


if(ENV.app.shouldPerformInitialNavigate()){
  routerProviders = provideRouter(routes)
}
else{
  // routerProviders =provideRouter(routes, withDisabledInitialNavigation());
  routerProviders = provideRouter(routes)
}


export const appConfig: ApplicationConfig = {
  providers: [
    // provideExperimentalZonelessChangeDetection(),
    routerProviders,
    // we use Multi-SPA not SSR not needed
    // provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi()),
    provideTranslateService({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:[HttpClient]
      }
    }),
    // @ts-ignore
    provideAppInitializer(waitFori18nextToLoad),
    {provide:HTTP_INTERCEPTORS,useClass:NewAzureAccessTokenInterceptor,multi:true},
    {provide:HTTP_INTERCEPTORS,useClass:XsrfInterceptor,multi:true},
    {provide:TitleStrategy,useClass:I18NSEOStrategy },

    // Sentry
    {
      provide: ErrorHandler,
      useFactory:()=> new GlobalErrorHandler({
        showDialog: false
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    }
  ]
};
