// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';




// rxjs
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil,tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';


// misc

import { ENV } from '@env/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';



@Component({
    selector: 'current-page-control-zero',
    templateUrl: './current-page-control-zero.component.html',
    styleUrls: ['./current-page-control-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrentPageControlZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('CurrentPageControlZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.currentPageControlZero)
  @Input('props') props: CurrentPageControlZeroProps = new CurrentPageControlZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  formGroup = new FormGroup({
    currentPage:new FormControl(0,[RxwebValidators.numeric({acceptValue:NumericValueType.PositiveNumber  ,allowDecimal:false })])
  })



  listenForCurrentPageStatusChanges = ()=>{
    return this.formGroup.get('currentPage').valueChanges
    .pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map(()=>this.formGroup.get('currentPage')),
      takeUntil(this.ngUnsub),
      tap((res)=>{

        if(res.status === 'VALID'){
          this.props.pageNumChangeEvent.next(this.formGroup.value.currentPage)
        }
      })
    )

  }

  listenForPropsChanges = ()=>{
    return this.props.updateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this.props = res
        this.formGroup.patchValue({
          currentPage:this.props.currentPage
        },{emitEvent:false})
        let currentPageFormControl = this.formGroup.get('currentPage')
        currentPageFormControl.clearValidators()
        currentPageFormControl.setValidators([
            Validators.required,
            RxwebValidators.numeric({acceptValue:NumericValueType.PositiveNumber  ,allowDecimal:false }),
            RxwebValidators.range({
              minimumNumber:1,
              maximumNumber:this.props.totalPages
            })
        ])

        this.cdref.detectChanges()
      })
    )
  }


  ngOnInit(): void {
    this.listenForCurrentPageStatusChanges().subscribe()
    this.listenForPropsChanges().subscribe()
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class CurrentPageControlZeroProps {
  constructor(props:Partial<CurrentPageControlZeroProps>={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }
  pageNumChangeEvent = new Subject<number>()
  updateSubj = new Subject<CurrentPageControlZeroProps>()
  currentPage:number
  totalPages:number
}


