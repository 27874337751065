// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';




// rxjs
import { Subject } from 'rxjs';

// wml-components
import { WMLUIProperty, generateClassPrefix } from '@windmillcode/wml-components-base';


// misc

import { ENV } from '@env/environment';
import { WMLButtonOneProps } from '@windmillcode/angular-wml-button';



@Component({
    selector: 'dashboard-ctrl-zero',
    templateUrl: './dashboard-ctrl-zero.component.html',
    styleUrls: ['./dashboard-ctrl-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DashboardCtrlZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService

  ) { }

  classPrefix = generateClassPrefix('DashboardCtrlZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.DashboardCtrlZero)

  @Input('props') props: DashboardCtrlZeroProps = new DashboardCtrlZeroProps()


  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId!:string;
  ngUnsub= new Subject<void>()

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}

export class DashboardCtrlZeroProps {
  constructor(props:Partial<DashboardCtrlZeroProps &
    {propTitle:string,
      propDesc:string,
      propActionBtnText:string,
      propActionBtnClick:WMLUIProperty["click"],
      propActionBtnId:string
    }
  >={}){
    Object.assign(
      this,
      {
        ...props
      }
    )


    this.title.text = props.propTitle ?? this.title.text
    this.desc.text = props.propDesc ?? this.desc.text
    this.actionBtn.text = props.propActionBtnText ?? this.actionBtn.text
    this.actionBtn.click = props.propActionBtnClick ?? this.actionBtn.click
    if(props.propActionBtnId){
      this.actionBtn.id = props.propActionBtnId
    }

  }

  title  =new WMLUIProperty({
    text:"My Default Action"
  })
  desc = new WMLUIProperty({
    text:`Control panel is not just a tool, but a virtual cockpit that places you at the helm of your data-driven voyage, offering unparalleled command over your information ecosystem.`
  })
  actionBtn = new WMLButtonOneProps({
    text:"DashboardCtrl.actionBtn",
    click:()=>{
      alert("Provide a function to the click method")
    }
  })
}



